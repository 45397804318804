<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        style="width: 150px; margin-right: 10px"
        class="filter-item"
        @change="getMajorList"
      >
        <el-option
          v-for="item in colleges"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.major_id"
        placeholder="请选择专业"
        style="width: 150px; margin-right: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in majors"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
        @change="getGradeList"
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.semester"
        placeholder="请选择学期"
        clearable
        style="width: 150px; margin-right: 10px"
        class="filter-item"
      >
        <el-option label="第一学期" :value="1" />
        <el-option label="第二学期" :value="2" />
      </el-select>
      <el-select
        v-model="listQuery.grade_id"
        placeholder="请选择年级"
        style="width: 150px; margin-right: 10px"
        class="filter-item"
        @change="getClassList"
      >
        <el-option
          v-for="item in grades"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.class_id"
        placeholder="请选择班级"
        style="width: 150px; margin-right: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in classes"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.type"
        placeholder="请选择状态"
        clearable
        style="width: 150px; margin-right: 10px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="代课" :value="2" />
        <el-option label="调课" :value="3" />
      </el-select>
      <el-date-picker
        v-model="Timedate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        class="filter-item"
      >
      </el-date-picker>
      <el-button class="filter-item" type="primary" @click="getList"
        >查找</el-button
      >
    </div>

    <div class="title">
      总共上课:{{ total_num }}课时&nbsp;&nbsp;&nbsp;正常上课:{{ nomal_num }}课时
      &nbsp;&nbsp;&nbsp;代课：共{{ replace_num }}课时
      &nbsp;&nbsp;&nbsp;调课次数: 共{{ change_num }}次
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" min-width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="课程" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.school_course.name }}
        </template>
      </el-table-column>
      <el-table-column label="上课教师" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.teacher.name }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.college.school.name }}
        </template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.school_year }}~{{
            scope.row.class_course.classes.school_year - 0 + 1
          }}
        </template>
      </el-table-column>
      <el-table-column label="学期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.semester == 1 ? "第一学期" : "第二学期" }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.college.name }}
        </template>
      </el-table-column>
      <el-table-column label="年纪" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.grade.name }}
        </template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_course.classes.name }}
        </template>
      </el-table-column>
      <el-table-column label="日期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.date }}
        </template>
      </el-table-column>
      <el-table-column label="第几节" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.class_time_config.name }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type == 1">正常</el-tag>
          <el-tag v-if="scope.row.type == 2">代课</el-tag>
          <el-tag v-if="scope.row.type == 3">调课</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        school_id: null,
        college_id: null,
        major_id: null,
        school_year: null,
        semester: null,
        grade_id: null,
        class_id: null,
        type: null,
        teacher_id: null,
      },
      list: [],
      total: null,
      listLoading: false,
      form: {
        id: "",
      },
      schools: [],
      colleges: [],
      majors: [],
      grades: [],
      classes: [],
      total_num: null,
      nomal_num: null,
      replace_num: null,
      change_num: null,
      teacher_id: null,
      Timedate: null,
    };
  },
  created() {
    this.listQuery.teacher_id = this.$route.query.teacher_id;
    this.getCollegeList();
    this.getList();
  },
  watch: {
    Timedate(newValue, oldValue) {
      console.log(newValue, oldValue);
      if (!newValue) {
        this.listQuery.start_at = null;
        this.listQuery.end_at = null;
      } else {
        this.listQuery.start_at = newValue[0];
        this.listQuery.end_at = newValue[1];
      }
    },
  },
  methods: {
    getSchoolList() {
      request({
        url: "/api/schoolend/school/list",
        method: "get",
        params: {
          limit: 99999999999999999,
        },
      }).then((response) => {
        this.schools = response.data.data;
      });
    },
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 99999999999999999,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getMajorList() {
      request({
        url: "/api/schoolend/school/majorList",
        method: "get",
        params: {
          limit: 99999999999999999,
          college_id: this.listQuery.college_id,
        },
      }).then((response) => {
        this.majors = response.data.data;
      });
    },
    getGradeList() {
      request({
        url: "/api/schoolend/school/gradeList",
        method: "get",
        params: {
          limit: 99999999999999999,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.grades = response.data.data;
      });
    },
    getClassList() {
      request({
        url: "/api/schoolend/school/classList",
        method: "get",
        params: {
          limit: 99999999999999999,
          college_id: this.listQuery.college_id,
          major_id: this.listQuery.major_id,
          grade_id: this.listQuery.grade_id,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.classes = response.data.data;
      });
    },
    handleJobManagement(row) {
      this.$router.push(
        `/schedule/operation?schedule_id=${row.id}&data=${JSON.stringify(row)}`
      );
    },
    handleStudent(row) {
      this.$router.push(
        `/schedule/student?class_id=${row.class_course.class_id}`
      );
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/schedule/scheduleLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.total_num = response.data.count.total_num;
        this.nomal_num = response.data.count.nomal_num;
        this.replace_num = response.data.count.replace_num;
        this.change_num = response.data.count.change_num;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 10px;
}
.el-table--border th.gutter:last-of-type {
  display: block !important;
}
.tip_box {
  display: flex;
  align-items: center;
  .tip {
    width: 20px;
    height: 20px;
    margin: 0 20px 0 5px;
  }
  .color_1 {
    background: black;
  }
  .color_2 {
    background: #11eeee;
  }
  .color_3 {
    background: #4db34d;
  }
  .color_4 {
    background: red;
  }
}
</style>
